import React from "react"
import { PageProps } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/seo"
import componentStyles from "./sportex.module.scss"
import SportexResult1 from "../../assets/images/projects/sportex-result-1.png"
import SportexResult2 from "../../assets/images/projects/sportex-result-2.png"
import { findIndex, projectsList } from "../../hooks"
import {
  getBigThumbnailByLink,
  useBigProjectsCardsThumbnails,
} from "../../hooks/useBigPreviewProjectsCardsThumbnails"
import projectsStyles from "../projects.module.scss"
import SmallProjectCard from "../../components/SmallProjectCard/SmallProjectCard"
import LearnMoreSection from "../../components/LearnMoreSection/LearnMoreSection"

const tilesItems = [
  {
    titlePath: "projects.sportex.technology-background.list.first-item.title",
    textPath: "projects.sportex.technology-background.list.first-item.text",
  },
  {
    titlePath: "projects.sportex.technology-background.list.second-item.title",
    textPath: "projects.sportex.technology-background.list.second-item.text",
  },
  {
    titlePath: "projects.sportex.technology-background.list.third-item.title",
    textPath: "projects.sportex.technology-background.list.third-item.text",
  },
  {
    titlePath: "projects.sportex.technology-background.list.forth-item.title",
    textPath: "projects.sportex.technology-background.list.forth-item.text",
  },
  {
    titlePath: "projects.sportex.technology-background.list.fifth-item.title",
    textPath: "projects.sportex.technology-background.list.fifth-item.text",
  },
]

const tableRows = [
  {
    columnFirst:
      "projects.sportex.solution-description.table.first-row.first-column",
    columnSecond:
      "projects.sportex.solution-description.table.first-row.second-column",
  },
  {
    columnFirst:
      "projects.sportex.solution-description.table.second-row.first-column",
    columnSecond:
      "projects.sportex.solution-description.table.second-row.second-column",
  },
  {
    columnFirst:
      "projects.sportex.solution-description.table.third-row.first-column",
    columnSecond:
      "projects.sportex.solution-description.table.third-row.second-column",
  },
  {
    columnFirst:
      "projects.sportex.solution-description.table.forth-row.first-column",
    columnSecond:
      "projects.sportex.solution-description.table.forth-row.second-column",
  },
  {
    columnFirst:
      "projects.sportex.solution-description.table.fifth-row.first-column",
    columnSecond:
      "projects.sportex.solution-description.table.fifth-row.second-column",
  },
]

const Sportex: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()
  const projects = projectsList
  const projectsThumbnails = useBigProjectsCardsThumbnails()

  const bannerClasses = classNames(componentStyles.banner)
  const bannerContainerClasses = classNames(
    componentStyles.bannerContainer,
    "container",
    "grid-2"
  )
  const solutionDescriptionClasses = classNames(
    componentStyles.solutionDescription,
    "container"
  )
  const solutionDescriptionTitleClasses = classNames(
    componentStyles.solutionDescriptionTitle,
    "large-section-title",
    "section-title-h2"
  )
  const solutionDescriptionTableHeaderClasses = classNames(
    componentStyles.solutionDescriptionTableHeader,
    componentStyles.solutionDescriptionTableRow
  )

  const technologyBackgroundClasses = classNames(
    componentStyles.technologyBackground,
    "container"
  )
  const technologyBackgroundTitleClasses = classNames(
    componentStyles.technologyBackgroundTitle,
    "large-section-title",
    "section-title-h2"
  )
  const technologyBackgroundTextClasses = classNames(
    componentStyles.technologyBackgroundText
  )
  const tilesListClasses = classNames(
    componentStyles.technologyBackgroundTilesList,
    "grid-3"
  )

  const resultsClasses = classNames(componentStyles.results, "container")
  const resultsTitleClasses = classNames(
    componentStyles.resultsTitle,
    "large-section-title",
    "section-title-h2"
  )
  const resultsListClasses = classNames(componentStyles.resultsList, "grid-2")
  const projectsTitleClasses = classNames("large-section-title", "section-title-h2")
  const blockPreview = classNames(projectsStyles.blockPreview, "container")
  const pathname = location.pathname
  const projectUrl = pathname.split("/")[3]
  let projectIndex = findIndex(projectUrl)
  const countProjects = projects.length
  projectIndex = projectIndex + 1 < countProjects ? projectIndex : 0

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({
          id: "projects.sportex.seo.description",
        })}
        title={intl.formatMessage({ id: "projects.sportex.seo.title" })}
        pathname={location.pathname}
      />
      <section className={bannerClasses}>
        <div className={bannerContainerClasses}>
          <div className={componentStyles.bannerWrapper}>
            <h1 className={componentStyles.bannerTitle}>
              {intl.formatMessage({
                id: "projects.sportex.banner.title",
              })}
            </h1>
          </div>
        </div>
      </section>
      <section className="container">
        <div className={componentStyles.clientCompany}>
          <h2 className="large-section-title section-title-h2">
            {intl.formatMessage({
              id: "projects.sportex.banner.client-company",
            })}
          </h2>
          <div className={componentStyles.sectionText}>
            {intl.formatMessage({
              id: "projects.sportex.banner.client-name",
            })}
          </div>
        </div>
      </section>
      <section className={solutionDescriptionClasses}>
        <div className={componentStyles.solutionDescriptionTextBlock}>
          <h2 className={solutionDescriptionTitleClasses}>
            {intl.formatMessage({
              id: "projects.sportex.solution-description.title",
            })}
          </h2>
          <p className={componentStyles.solutionDescriptionText}>
            {intl.formatMessage({
              id: "projects.sportex.solution-description.text",
            })}
          </p>
        </div>
        <div className={componentStyles.solutionDescriptionTableWrapper}>
          <table className={componentStyles.solutionDescriptionTable}>
            <tr className={solutionDescriptionTableHeaderClasses}>
              <th className={componentStyles.solutionDescriptionTableCell}>
                {intl.formatMessage({
                  id:
                    "projects.sportex.solution-description.table.header.first-column",
                })}
              </th>
              <th className={componentStyles.solutionDescriptionTableCell}>
                {intl.formatMessage({
                  id:
                    "projects.sportex.solution-description.table.header.second-column",
                })}
              </th>
            </tr>
            {tableRows.map(({ columnFirst, columnSecond }) => (
              <tr
                key={columnFirst}
                className={componentStyles.solutionDescriptionTableRow}
              >
                <td className={componentStyles.solutionDescriptionTableCell}>
                  {intl.formatMessage({
                    id: columnFirst,
                  })}
                </td>
                <td className={componentStyles.solutionDescriptionTableCell}>
                  {intl.formatMessage({
                    id: columnSecond,
                  })}
                </td>
              </tr>
            ))}
          </table>
        </div>
      </section>
      <section className={technologyBackgroundClasses}>
        <h2 className={technologyBackgroundTitleClasses}>
          {intl.formatMessage({
            id: "projects.sportex.technology-background.title",
          })}
        </h2>
        <p className={technologyBackgroundTextClasses}>
          {intl.formatMessage({
            id: "projects.sportex.technology-background.text",
          })}
        </p>
        <ul className={tilesListClasses}>
          {tilesItems.map(({ textPath, titlePath }) => (
            <li
              key={titlePath}
              className={componentStyles.technologyBackgroundTilesItem}
            >
              <h4 className={componentStyles.technologyBackgroundTilesTitle}>
                {intl.formatMessage({
                  id: titlePath,
                })}
              </h4>
              {intl.formatMessage({
                id: textPath,
              })}
            </li>
          ))}
        </ul>
      </section>
      <section className={resultsClasses}>
        <h2 className={resultsTitleClasses}>
          {intl.formatMessage({
            id: "projects.sportex.results.title",
          })}
        </h2>
        <p className={componentStyles.resultsText}>
          {intl.formatMessage({
            id: "projects.sportex.results.paragraph-1",
          })}
        </p>
        <div className={componentStyles.resultsImageWrapper}>
          <div className={resultsListClasses}>
            <img
              src={SportexResult1}
              className={componentStyles.resultsImage}
              alt={intl.formatMessage({
                id: "projects.sportex.results.first-image-alt",
              })}
              width="564"
              height="349"
            />
            <img
              src={SportexResult2}
              className={componentStyles.resultsImage}
              alt={intl.formatMessage({
                id: "projects.sportex.results.second-image-alt",
              })}
              width="564"
              height="349"
            />
          </div>
        </div>
      </section>
      <section className={blockPreview}>
        <h2 className={projectsTitleClasses}>
          {intl.formatMessage({ id: "index.projects.next-project" })}
        </h2>
        {projects
          .filter(project => project.link !== projectUrl)
          .slice(projectIndex, projectIndex + 1)
          .map(({ title, color, link }) => {
            const thumbnail = getBigThumbnailByLink(link, projectsThumbnails)
            return (
              <SmallProjectCard
                key={title}
                title={title}
                thumbnail={thumbnail}
                link={link}
                color={color}
                buttonColor="white"
              />
            )
          })}
      </section>
      <LearnMoreSection/>
    </Layout>
  )
}
export default Sportex
